import * as React from "react"
import * as css from "./vacancies-screen.module.scss"
import classNames from "classnames"

const vacanciesInfoList: {
  title: string
  url: string
  position: string[]
  employment: string
  workplace: string
}[] = [
  {
    title: "Product Manager",
    url: "https://obrio-76be502da02f.breezy.hr/p/3bfaa08490e1-product-manager",
    position: ["/deco/junior.svg", "/deco/middle.svg"],
    employment: "Full-time",
    workplace: "Remote, Office",
  },
  {
    title: "Node.js Developer",
    url: "https://obrio-76be502da02f.breezy.hr/p/2287aa7453ae-node-js-developer",
    position: ["/deco/junior.svg", "/deco/middle.svg", "/deco/senior.svg"],
    employment: "Full-time",
    workplace: "Remote, Office",
  },
  {
    title: "User Acquisition Manager",
    url: "https://obrio-76be502da02f.breezy.hr/p/5f8cd87846fc-growth-marketing-specialist-facebook",
    position: ["/deco/middle.svg"],
    employment: "Full-time",
    workplace: "Remote, Office",
  },
  {
    title: "Manual QA Engineer",
    url: "https://obrio-76be502da02f.breezy.hr/p/b9d6adcb083b-middle-manual-qa-engineer",
    position: ["/deco/middle.svg"],
    employment: "Full-time",
    workplace: "Remote, Office",
  },
]

export const VacanciesList = () => {
  return (
    <div className={css.vacanciesList}>
      {vacanciesInfoList.map((vacancy, index) => (
        <a
          key={index}
          className={classNames(css.vacancy, `list-vacancy-${index}`)}
          href={vacancy.url}
          target="_blank"
        >
          <div className={css.img}>
            {vacancy.position.map((position, index) => (
              <img key={index} src={position} alt="" />
            ))}
          </div>
          <span className={css.vacancyTitle}>{vacancy.title}</span>
          <div className={css.schedule}>
            <span className={css.employment}>{vacancy.employment}</span>
            <span className={css.workplace}>{vacancy.workplace}</span>
          </div>
          <img className={css.arrow} src="/deco/arrow-right.svg" alt="" />
        </a>
      ))}
    </div>
  )
}
